<template>
  <div class="">
    <el-form
      :model="ruleForm"
      :rules="rules"
      label-position="top"
      ref="ruleForm"
      label-width="100px"
      class="ruleForm"
    >
      <el-form-item label="公司名称" prop="company_name">
        <el-input v-model="ruleForm.company_name"></el-input>
      </el-form-item>
      <el-form-item label="联系人" prop="contact_name">
        <el-input v-model="ruleForm.contact_name"></el-input>
      </el-form-item>
      <el-form-item label="职位" prop="position">
        <el-input v-model="ruleForm.position"></el-input>
      </el-form-item>
      <el-form-item label="联系电话" prop="contact_tel">
        <el-input v-model="ruleForm.contact_tel"></el-input>
      </el-form-item>
      <el-form-item label="需求描述" prop="requirement_description">
        <el-input
          type="textarea"
          :rows="4"
          placeholder="请输入内容"
          v-model="ruleForm.requirement_description"
        >
        </el-input>
      </el-form-item>
      <!-- <el-form-item> -->
      <div class="bottom">
        <el-button
          class="btnSize"
          type="primary"
          size="small"
          @click="submitForm('ruleForm')"
          >提交</el-button
        >
      </div>
      <!-- </el-form-item> -->
    </el-form>
  </div>
</template>

<script>
import { submitConsulting } from "@/api/platformCenter";
export default {
  data() {
    return {
      ruleForm: {
        company_name: "",
        contact_name: "",
        position: "",
        contact_tel: "",
        requirement_description: "",
      },
      rules: {
        company_name: [
          { required: true, message: "请输入公司名称", trigger: "blur" },
        ],
        contact_name: [
          { required: true, message: "请输入联系人", trigger: "blur" },
        ],
        position: [{ required: true, message: "请输入职位", trigger: "blur" }],
        contact_tel: [
          { required: true, message: "请输入联系电话", trigger: "blur" },
        ],
        requirement_description: [
          { required: true, message: "请输入需求描述", trigger: "blur" },
        ],
      },
    };
  },
  methods: {
    showForm() {
      this.$emit("showForm");
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          submitConsulting(this.ruleForm).then(({ res }) => {
            this.$toast("提交成功");
            this.ruleForm = {
              company_name: "",
              contact_name: "",
              position: "",
              contact_tel: "",
              requirement_description: "",
            };
            this.showForm();
          });
        } else {
          return false;
        }
      });
    },
  },
};
</script>

<style lang='scss' scoped>
::v-deep .el-form-item__label {
  padding: 0 !important;
}

.bottom {
  text-align: center;
  margin-bottom: 10px;
  .btnSize {
    width: 140px;
    height: 32px;
    background: #2c5afe;
  }
}
.popover {
  .title {
    position: relative;
    margin: 11px 12px 0 15px;
    font-size: 16px;
    font-weight: bold;
    color: #262626;
    line-height: 24px;
    .close {
      position: absolute;
      top: 0;
      right: 0;
      font-size: 18px;
      font-weight: bold;
    }
  }
  .ruleForm {
    margin: 16px 12px 0 15px;
  }

  .bottom {
    text-align: center;
    margin-bottom: 10px;
    .btnSize {
      width: 140px;
      height: 32px;
    }
  }
}
</style>

