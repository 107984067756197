import CryptoJS from "crypto-js";
import * as dd from "dingtalk-jsapi";
import common from "./tool.js";
const sysFn = {
  /**
 * 整理自定义列属性配置
 * colObj 列的对象属性
 */
  getCustomColConfig (colObj) {
    //1.清空列配置
    let config = {}

    //2.重新设置列配置
    colObj.align && (config['align'] = colObj.align);
    colObj.headerAlign && (config['headerAlign'] = colObj.headerAlign);
    colObj.footerAlign && (config['footerAlign'] = colObj.footerAlign);
    colObj.width && (config['width'] = colObj.width);
    colObj.type && colObj.type != 'dragRow' && (config['type'] = colObj.type);
    colObj.fixed && (config['fixed'] = colObj.fixed);
    colObj.field && (config['field'] = colObj.field);
    colObj.title && colObj.title !== 'undefined' && (config['title'] = i18n.t(colObj.title));
    colObj.treeNode && (config['treeNode'] = true);
    colObj.field != "operate" && colObj.showOverflow && (config['showOverflow'] = colObj.showOverflow);
    colObj.showHeaderOverflow && (config['showHeaderOverflow'] = colObj.showHeaderOverflow);
    colObj.showFooterOverflow && (config['showFooterOverflow'] = colObj.showFooterOverflow);

    //不设置sortable由自定义表头控制排序

    //控制是否显示
    let visible = colObj.visible;
    if (colObj.permissionFiled) {//是否权限
      visible = visible && colObj.getShowState()
    }
    config['visible'] = visible;

    colObj.field != "operate" && colObj.resizable && (config['resizable'] = true);
    colObj.minWidth && (config['minWidth'] = colObj.minWidth);

    //编辑配置
    config['editRender'] = colObj.getEditRender();

    colObj.isFormatter && (config['formatter'] = colObj.formatter);
    colObj.filters && (config['filters'] = colObj.filters);
    if (colObj.showOverflow) {
      config['className'] = "v-nowrap " + colObj.classNames ? colObj.classNames : "";
    } else {
      config['className'] = colObj.classNames + colObj.classNames ? colObj.classNames : "";
    }

    if (colObj.classNames) {
      config['headerClassName'] = colObj.classNames;
      config['footerClassName'] = colObj.classNames;
    }

    // if(!colObj.type){
    //   config['slots'] = {}
    //   colObj.field != 'operate' && (config['slots']['header'] = colObj.field+'_header');
    //   config['slots']['default'] = colObj.field+'_default';
    // }

    if (colObj.field == 'operate') {
      colObj.title && colObj.title !== 'undefined' && (config['title'] = colObj.title);
    }

    return config;
  },
  /**
   * 将扁平数据转为树形结构
   * @param sourceData {Array} 扁平源数据
   * */
  transformTree (sourceData, params = {}) {
    let {
      id = "id",
      pid = "pId",
      children = "children",
      label = "label",
      value = "value",
    } = params;
    let data = JSON.parse(JSON.stringify(sourceData));
    let obj = {}; // 使用对象重新存储数据
    let res = []; // 存储最后结果
    let len = data.length;

    // 遍历原始数据data，构造obj数据，键名为id，值为数据
    for (let i = 0; i < len; i++) {
      obj[data[i][id]] = data[i];
    }

    // 遍历原始数据
    for (let j = 0; j < len; j++) {
      let list = data[j];
      list["label"] = list[label];
      list["value"] = list[value];
      // 通过每条数据的 pid 去obj中查询
      if (!list[pid]) {
        list[pid] = "0";
      }
      let parentList = obj[list[pid]];

      if (parentList && parentList[pid] != parentList[id]) {
        // 根据 pid 找到的是父节点，list是子节点，
        if (!parentList[children]) {
          parentList[children] = [];
        }
        // 将子节点插入 父节点的 children 字段中
        obj[list[pid]][children].push(list);
      } else {
        // pid 找不到对应值，说明是根结点，直接插到根数组中
        res.push(list);
      }
    }

    return res;
  },
  KEY: "abcdefghijklmnopqrstuvwxyz012345", // 32位
  IV: "9876543210abcdef", // 16位
  /** ************************************************************
   *Crypto字符串加密
   * str：需要加密的字符串
   * key：加密密钥
   * method: 加密算法 MD5/AES/SHA256/Base64
   * mode:加密模式
   * padding:填充方式
   *************************************************************** */
  getEncrypt (
    str,
    method = "AES",
    key = sysFn.KEY,
    iv = sysFn.IV,
    config = {
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    }
  ) {
    let encrypted = "";
    let srcs = str;
    if (method.toLocaleUpperCase() === "MD5") {
      encrypted = CryptoJS.MD5(srcs).toString();
    } else if (method.toLocaleUpperCase() === "SHA256") {
      encrypted = CryptoJS.SHA256(srcs).toString();
    } else if (method.toLocaleUpperCase() === "BASE64") {
      srcs = CryptoJS.enc.Utf8.parse(str);
      encrypted = CryptoJS.enc.Base64.stringify(srcs);
    } else if (
      method.toLocaleUpperCase() === "AES" ||
      method.toLocaleUpperCase() === "DES"
    ) {
      srcs = CryptoJS.enc.Utf8.parse(str);
      key = CryptoJS.enc.Utf8.parse(key);
      iv = CryptoJS.enc.Utf8.parse(iv);
      encrypted = CryptoJS[method.toLocaleUpperCase()].encrypt(srcs, key, {
        iv,
        mode: config.mode,
        padding: config.padding,
      });
      encrypted = encrypted.ciphertext.toString();
    } else {
      encrypted = CryptoJS[method.toLocaleUpperCase()](srcs).toString();
    }

    return encrypted;
  },

  /** ************************************************************
   *Crypto字符串解密
   * str：需要解密的字符串
   * key：解密密钥
   *************************************************************** */
  getDecrypt (
    str,
    method = "AES",
    key = sysFn.KEY,
    iv = sysFn.IV,
    config = {
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    }
  ) {
    let decrypt = "";
    if (method.toLocaleUpperCase() === "BASE64") {
      decrypt = CryptoJS.enc.Base64.parse(str).toString(CryptoJS.enc.Utf8);
    } else if (
      method.toLocaleUpperCase() === "AES" ||
      method.toLocaleUpperCase() === "DES"
    ) {
      key = CryptoJS.enc.Utf8.parse(key);
      iv = CryptoJS.enc.Utf8.parse(iv);
      const encryptedHexStr = CryptoJS.enc.Hex.parse(str);
      const srcs = CryptoJS.enc.Base64.stringify(encryptedHexStr);
      decrypt = CryptoJS[method.toLocaleUpperCase()].decrypt(srcs, key, {
        iv,
        mode: config.mode,
        padding: config.padding,
      });
    }
    let decryptedStr = "right";
    try {
      decryptedStr = decrypt.toString(CryptoJS.enc.Utf8);
    } catch (err) {
      decryptedStr = "error";
    }

    return decryptedStr.toString();
  },
  ddLogin () {
    return new Promise((resolve, reject) => {
      const userAgent = navigator.userAgent;
      if (userAgent.indexOf("DingTalk") !== -1) {
        console.log('dd.ready 即将执行')
        dd.ready(function () {
          console.log("corpId: ==", common.getUrlParam("corpId"));
          let cropId = common.getUrlParam("corpId") || sessionStorage.getItem("corpId") || localStorage.getItem("corpId");
          if (cropId) {
            cropId = cropId.replace(/[^a-z0-9]+/gi, "");
            // 获得免登授权码
            dd.runtime.permission.requestAuthCode({
              corpId: cropId,
              onSuccess: async function (info) {
                console.log("info: ", info);
                const params = {
                  code: info.code,
                  corpId: cropId,
                };

                resolve(params);
              },
              onFail: function (err) {
                console.error("fail: " + JSON.stringify(err));
                reject(err);
              },
            });
          }
        });
        dd.error((error) => {
          console.warn(`dd error: ${ JSON.stringify(error) }`);
          reject(`dd error: ${ JSON.stringify(error) }`);
        });
      } else {
        console.warn("非钉钉环境!");
        reject("非钉钉环境!");
      }
    });
  },
};

export default sysFn;
