<template>
  <el-dialog
    :title="dialogTitle"
    :visible.sync="visible"
    width="480px"
    :before-close="handleClose"
    @open="handleOpen"
    append-to-body
    :fullscreen="false"
    top="15vh"
    :modal="true"
    :close-on-click-modal="false"
    :class="[`add-client-dialog-wrapper client-add-out-wrapper-${ruleForm.companyType}`]"
    destroy-on-close
  >
    <div class="client-add-out-wrapper">
      <div class="add-client-wrapper" v-show="!ruleForm.companyType">
        <div class="client-item" @click="onImgHandle(item)" v-for="(item, index) in clientList" :key="index">
          <img class="bg-image" :src="item.bgImage" alt="" srcset="" />
          <div class="top-text">{{item.text}} <i class="iconfont nti-hou"></i></div>
          <div class="bottom-text">{{item.description}}</div>
        </div>
      </div>
      <div class="join-invite-team-wrapper" v-show="ruleForm.companyType">
        <div class="team-top" v-show="ruleForm.companyType == 1"> 企业邀请码是一个由字母组成的8位编码，可通过管理员获取。 </div>
        <div class="team-top" v-show="ruleForm.companyType == 2"> 注册新企业或组织，填写真实信息，以管理员。 </div>
        <el-form ref="ruleForm" :model="ruleForm" :rules="rules" label-position="left">
          <el-form-item label="企业邀请码" v-if="ruleForm.companyType == 1" style="margin-bottom: 0 !important" prop="inviteCode" label-width="100px">
            <el-input v-model="ruleForm.inviteCode" placeholder="请输入" size="medium" class="name-input"> </el-input>
          </el-form-item>
          <template v-if="ruleForm.companyType == 2">
            <el-form-item label="企业或组织名称" prop="name" label-width="130px">
              <el-input maxlength="50" v-model="ruleForm.name" placeholder="请输入" size="medium" class="name-input"> </el-input>
            </el-form-item>
            <el-form-item label="行业类型" prop="tradeType" label-width="130px">
              <el-cascader v-model="ruleForm.tradeType"  size="medium" placeholder="请选择" style="width: 100%" :options="ruleForm.tradeTypeList"></el-cascader>
            </el-form-item>
            <el-form-item label="人员规模" style="margin-bottom: 0 !important" prop="workerSize" label-width="130px">
              <el-select style="width: 100%;" v-model="ruleForm.workerSize" placeholder="请选择">
                <el-option label="1-9人" :value="1"></el-option>
                <el-option label="10-99人" :value="2"></el-option>
                <el-option label="100-299人" :value="3"></el-option>
                <el-option label="300-499人" :value="4"></el-option>
                <el-option label="500-999人" :value="5"></el-option>
                <el-option label="1000-2999人" :value="6"></el-option>
                <el-option label="3000人以上" :value="7"></el-option>
              </el-select>
            </el-form-item>
          </template>
        </el-form>
      </div>
      <div class="client-company-end-wrapper" v-if="[3, 4, 5].includes(ruleForm.companyType)">
        <template v-for="(item, index) in companyEndList" v-if="item.active" :index="index">
          <img class="comany-bg-image" :src="item.bgImage" alt="" srcset="" />
          <div class="center-text">{{ item.text }}</div>
          <div v-if="item.value != 5" class="desc-text-wrapper"
            >前往<span @click="onLinkTo(item)" class="active">{{ item.descleft }}</span
            ><span>，{{ item.descRight }}</span></div
          >
          <el-button v-if="item.value == 5" class="btn" type="primary" @click="onback(item)">确定</el-button>
        </template>
      </div>
    </div>
    <div slot="footer" class="member-dialog-footer" v-if="ruleForm.companyType == 2 || ruleForm.companyType == 1">
      <el-button class="close-btn" @click="onUp">上一步</el-button>
      <el-button class="btn" type="primary" @click="onNext">下一步</el-button>
    </div>
  </el-dialog>
</template>
<script>
import { addClient, validAndBind } from '@/api/platformCenter/index.js';
export default {
  props: {
    visible: {
      type: [Boolean, String],
      default() {
        return false;
      }
    }
  },
  data() {
    const validatePass2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入8位数的企业邀请码'));
      } else {
        if (value.length != 8) {
          callback(new Error('请输入8位数的企业邀请码'));
        }
        callback();
      }
    };
    return {
      ruleForm: {
        companyType: '',
        inviteCode: '',
        name: '',
        tradeType: [],
        workerSize: null,
        tradeTypeList: [
          {
            value: 0,
            label: '制造业',
            children: [
              {
                value: 1,
                label: '石油石化/日化'
              },
              {
                value: 2,
                label: '电气/机械设备'
              },
              {
                value: 3,
                label: '交通运输设备'
              },
              {
                value: 4,
                label: '航空航天'
              },
              {
                value: 5,
                label: '军工'
              },
              {
                value: 6,
                label: '芯片/半导体'
              },
              {
                value: 7,
                label: '其他'
              }
            ]
          },
          {
            value: 8,
            label: '烟草',
            children: [
              {
                value: 9,
                label: '烟草'
              }
            ]
          },
          {
            value: 10,
            label: '能源',
            children: [
              {
                value: 11,
                label: '传统能源'
              },
              {
                value: 12,
                label: '新能源'
              },
              {
                value: 13,
                label: '其他'
              }
            ]
          },
          {
            value: 14,
            label: '汽车',
            children: [
              {
                value: 15,
                label: '电池/电机'
              },
              {
                value: 16,
                label: '新能源汽车'
              },
              {
                value: 17,
                label: '传统汽车'
              },
              {
                value: 18,
                label: '其他'
              }
            ]
          },
          {
            value: 19,
            label: '消费电子',
            children: [
              {
                value: 20,
                label: '手机/数码'
              },
              {
                value: 21,
                label: '家电'
              },
              {
                value: 22,
                label: '其他'
              }
            ]
          },
          {
            value: 23,
            label: '软件和信息',
            children: [
              {
                value: 24,
                label: 'IT基础设施'
              },
              {
                value: 25,
                label: '信息安全'
              },
              {
                value: 26,
                label: '其他'
              }
            ]
          },
          {
            value: 27,
            label: '消品',
            children: [
              {
                value: 28,
                label: '百货'
              },
              {
                value: 29,
                label: '食品饮料酒'
              },
              {
                value: 30,
                label: '其他'
              }
            ]
          },
          {
            value: 31,
            label: '通信/电力',
            children: [
              {
                value: 32,
                label: '通信/电力'
              }
            ]
          },
          {
            value: 33,
            label: '医疗和健康',
            children: [
              {
                value: 34,
                label: '医药'
              },
              {
                value: 35,
                label: '医疗机械'
              },
              {
                value: 36,
                label: '生物技术'
              }
            ]
          },
          {
            value: 37,
            label: '其他',
            children: [
              {
                value: 37,
                label: '其他'
              }
            ]
          }
        ]
      },
      rules: {
        inviteCode: [
          {
            required: true,
            validator: validatePass2,
            trigger: ['blur', 'change']
          }
        ],
        name: [
          {
            required: true,
            message: '请输入企业或组织名称',
            trigger: ['blur', 'change']
          }
        ],
        tradeType: [{ required: true, message: '请选择行业类型', trigger: 'change' }],
        workerSize: [{ required: true, message: '请选择人员规模', trigger: 'change' }]
      },
      clientList: [
        {
          value: 1,
          text:  '已有企业',
          description:  '前往加入',
          bgImage: require('@/assets/index/client_n.png')
        },
        {
          value: 2,
          text: '没有企业',
          description: '立即创建',
          bgImage: require('@/assets/index/client_y.png')
        }
      ],
      companyEndList: [
        {
          value: 3,
          type: 'success',
          text: '加入企业成功',
          descleft: '工作台',
          descRight: '开始今天云之旅',
          bgImage: require('@/assets/index/client_s.png'),
          active: false,
          link: '/workbench'
        },
        {
          value: 4,
          type: 'success',
          text: '加入企业成功',
          descleft: '添加成员',
          descRight: '开启高效协作',
          bgImage: require('@/assets/index/client_s.png'),
          active: false,
          link: '/workbench'
        },
        {
          value: 5,
          type: 'error',
          text: '企业邀请码错误，请检查后重新输入',
          descleft: '',
          descRight: '',
          bgImage: require('@/assets/index/client_w.png'),
          active: false,
          link: '/workbench'
        }
      ]
    };
  },
  computed: {
    dialogTitle() {
      if (!this.ruleForm.companyType) {
        return '添加账号';
      } else if (this.ruleForm.companyType == 1) {
        return '输入企业邀请码';
      } else if (this.ruleForm.companyType == 2) {
        return '设置企业信息';
      }
      return '';
    }
  },
  methods: {
    handleOpen() {
      this.ruleForm.id = '';
      this.ruleForm.name = '';
      this.ruleForm.companyType = '';
      this.ruleForm.tradeType = [];
      this.ruleForm.workerSize = null;
    },
    onUp() {
      this.ruleForm.companyType = '';
    },
    addClientHandle() {
      let userInfo = sessionStorage.getItem("userInfo");
      const userId = this.$store.state.userInfo.id;
      addClient({
        clientCode: this.ruleForm.name,
        clientName: this.ruleForm.name,
        tradeType: this.ruleForm.tradeType[1],
        workerSize: this.ruleForm.workerSize,
        userId: userId
      }).then(({ res }) => {
        for (const element of this.companyEndList) {
          element.active = false;

          if (element.value == 4) {
            this.ruleForm.companyType = element.value;
            element.active = true;
            this.$emit("update")
            // 此时需要更新租户
            // this.$store.dispatch('platformCenter/getUserInfo');
          }
        }
      });
    },
    onInviteCompany() {
      let userInfo = sessionStorage.getItem("userInfo");
      const userId = this.$store.state.userInfo.id;
      validAndBind({
          inviteCode: this.ruleForm.inviteCode,
          userId: userId
        }).then(async ({res}) => {
          for (const element of this.companyEndList) {
            element.active = false;
            if (element.value == 3) {
              this.ruleForm.companyType = element.value;
              element.active = true;
              this.$emit("update")
            }
          }

        }).catch(err => {
          for (const element of this.companyEndList) {
            element.active = false;
            if (element.value == 5) {
              this.ruleForm.companyType = element.value;
              element.active = true;
            }
          }
        })
    },
    onNext() {
      this.$refs['ruleForm'].validate(valid => {
        if (valid) {
          if (this.ruleForm.companyType == 2) {
            this.addClientHandle();
          } else if (this.ruleForm.companyType == 1) {
            this.onInviteCompany();
          }
        } else {
          return false;
        }
      });
      //   if (this.ruleForm.companyType == 1) {
      //    企业种
      // 请求接口 成功
      // for (const element of this.companyEndList) {
      //   element.active = false;
      //   // if (element.value == 3) {
      //   //     this.ruleForm.companyType = element.value
      //   //     element.active = true
      //   // }
      //   if (element.value == 5 && this.ruleForm.companyType == 1) {
      //     this.ruleForm.companyType = element.value;
      //     element.active = true;
      //   }
      //   if (this.ruleForm.companyType == 2 && element.value == 4) {
      //     this.ruleForm.companyType = element.value;
      //     element.active = true;
      //   }
      // }
      //   }
    },
    onback() {
      for (const element of this.companyEndList) {
        element.active = false;
      }
      this.ruleForm.companyType = 1;
    },
    onLinkTo(data) {
      this.$router.push('');
    },
    onClose() {},
    submitForm(formName) {
      this.ruleForm.companyType = 'companyAdd';
      this.$refs['ruleForm'].validate(valid => {
        if (valid) {
          this.$emit('invite', this.ruleForm);
        } else {
          return false;
        }
      });
    },
    onImgHandle(item) {
      this.ruleForm.companyType = item.value;
    },
    handleClose() {
      this.$nextTick(() => {
        this.$emit("update:visible", false);
      });
    }
  }
};
</script>
<style lang="scss" scoped>
.client-add-out-wrapper {
  margin-top: 24px;
}
.add-client-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  .client-item {
    width: 432px;
    height: 96px;
    margin-bottom: 16px;
    position: relative;
    &:last-child {
      margin-bottom: 0;
    }
    .bg-image {
      width: 432px;
      height: 96px;
      border-radius: 4px;
      cursor: pointer;
    }
    .top-text {
      position: absolute;
      top: 24px;
      left: 24px;
      z-index: 2;
      font-weight: bold;
      font-size: 16px;
      color: #262626;
      margin-right: 4px;
    }

    .bottom-text {
      position: absolute;
      top: 57px;
      left: 24px;
      z-index: 2;
      font-size: 14px;
      font-weight: 400;
      font-size: 14px;
      color: #595959;
    }
  }
}
.join-invite-team-wrapper {
  .team-top {
    margin-bottom: 16px;
    font-weight: 400;
    font-size: 14px;
    color: #595959;
  }
}
.client-company-end-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom: 33px;
  .comany-bg-image {
    width: 120px;
    height: 120px;
    margin-bottom: 16px;
  }
  .center-text {
    font-weight: bold;
    font-size: 16px;
    color: #262626;
    margin-bottom: 16px;
  }
  .desc-text-wrapper {
    font-weight: 400;
    font-size: 14px;
    color: #595959;
    .active {
      /* color: var(--menuMainBg); */
      cursor: pointer;
    }
  }
}
</style>
<style lang="scss">
.client-add-out-wrapper {
  .el-form-item__label {
    color: #262626;
    text-align: right;
  }
  .el-form-item--small.el-form-item {
    margin-bottom: 16px !important;
  }
}
.client-add-out-wrapper-3,
.client-add-out-wrapper-4,
.client-add-out-wrapper-5 {
  .el-dialog__header {
    border-bottom: 0;
  }
  .client-company-end-wrapper {
    padding-top: 17px;
  }
}
.client-add-out-wrapper-5 {
  .client-company-end-wrapper {
    padding-bottom: 13px;
  }
}
.add-client-dialog-wrapper {
  .el-dialog__body {
    padding-top: 0;
  }
}
</style>
