<template>
  <div>
    <mobile-header v-if="!mobileLogin"></mobile-header>
    <router-view
      class="body"
      :class="{ 'pt-40': !mobileLogin }"
      v-if="isRouterAlive"
    />
    <mobile-footer v-if="!footerShow"></mobile-footer>
    <mobile-consulting-Services
      v-if="!mobileLogin"
    ></mobile-consulting-Services>
  </div>
</template>

<script>
import mobileHeader from "@/views/mobileCom/MobileHeader.vue";
import mobileFooter from "@/views/mobileCom/MobileFooter.vue";
import MobileConsultingServices from "@/views/mobileCom/MobileConsultingServices.vue";
import Footer from "../components/Footer.vue";
export default {
  name: "mobileHomePage",
  components: {
    mobileHeader,
    mobileFooter,
    MobileConsultingServices,
  },
  provide() {
    return {
      reload: this.reload,
    };
  },
  data() {
    return {
      isRouterAlive: true,
    };
  },
  methods: {
    reload() {
      this.isRouterAlive = false;
      this.$nextTick(() => {
        this.isRouterAlive = true;
      });
    },
  },
  computed: {
    mobileLogin() {
      return this.$route.path === "/login";
    },
    footerShow() {
      return (
        this.$route.path === "/login" ||
        this.$route.path === "/appDetails" ||
        this.$route.path === "/applicationCenter"
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.body {
  min-height: calc(100% - 134px);
}
.pt-40 {
  padding-top: 44px;
}
</style>
