<template>
  <div :class="$route.path != '/appDetails' ? '' : 'maintop'">
    <div class="main">
      <div class="left">
        <div class="title">工业互联网平台</div>
        <div class="text">
          一个具备“低代码快速开发、OT数据采集开发、大数据开发、数字孪生构建”四大使能工具的工业互联网平台
        </div>
      </div>
      <div class="right">
        <div class="codeDiv marginRight">
          <img class="imgSize" src="@/assets/common/qwx_code.png" alt="" />
          <div class="">微信公众号</div>
        </div>
        <div class="codeDiv">
          <img class="imgSize" src="@/assets/common/dd_code.png" alt="" />
          <div class="">钉钉</div>
        </div>
      </div>
      <!-- <van-divider /> -->
      <!-- <div>周一至周五 09:00-18:00</div> -->
    </div>
    <!-- <div class="message">
      <div>深圳市今天国际物流技术股份有限公司 版权所有 粤ICP备</div>
      <div>12074812号 Copyright © 2000-2022 NTI. All Rights Reserved</div>
    </div> -->
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.maintop {
  padding-bottom: 44px;
}
.main {
  display: flex;
  background: #010c1e;
  height: 133px;
  color: #fff;
  padding: 24px 14px 20px;
  .title {
    // padding: 22px 14px;
    margin-bottom: 10px;
    font-size: 14px;
  }
  .text {
    width: 210px;
    margin-right: 10px;
    line-height: 20px;
  }
  .right {
    display: flex;
    margin-top: 17px;
    .marginRight {
      margin-right: 10px;
    }
    .codeDiv {
      text-align: center;
      .imgSize {
        width: 57px;
      }
    }
  }
}
.message {
  height: 40px;
  margin: 10px;
  div {
    display: flex;
    justify-content: center;
  }
}
</style>