<template>
  <div class="main">
    <div>
      <div class="service" slot="reference" @click="visible = !visible">
        <img
          class="imgSize"
          src="@/assets/consultingServices/message@2x.png"
          alt=""
        />
        <div class="text">服 务 咨 询</div>
      </div>
      <van-popup
        class="popup"
        v-model="visible"
        position="bottom"
        :style="{ height: '86%' }"
        closeable
      >
        <div class="title">咨询留言</div>
        <div class="popup-content">
          <mobile-consulting-message
            @showForm="showForm"
          ></mobile-consulting-message>
        </div>
      </van-popup>
    </div>
    <div class="artificial">
      <el-popover placement="right" width="220" trigger="click">
        <div class="phoneDiv">
          <div class="codeDiv">
            <span class="text">企业微信</span>
            <img class="imgSize" src="@/assets/common/qwx_code.png" alt="" />
          </div>
          <div class="codeDiv">
            <span class="text">钉钉联系方式</span>
            <img class="imgSize" src="@/assets/common/dd_code.png" alt="" />
          </div>

          <!-- <img class="imgSize" src="@/assets/consultingServices/phone@2x.png" alt="">
        400-6136-656 -->
        </div>
        <img
          slot="reference"
          src="@/assets/consultingServices/artificial@2x.png"
          alt=""
        />
      </el-popover>
    </div>
    <div class="goTop" @click="goTop">
      <img src="@/assets/consultingServices/goTop@2x.png" alt="" />
    </div>
  </div>
</template>

<script>
import MobileConsultingMessage from "@/views/mobileCom/MobileConsultingMessage.vue";
export default {
  components: {
    MobileConsultingMessage,
  },
  data() {
    return {
      visible: false,
      ruleForm: {
        company: "",
        contacts: "",
        position: "",
        phone: "",
        describe: "",
      },
      rules: {
        company: [
          { required: true, message: "请输入公司名称", trigger: "blur" },
        ],
        contacts: [
          { required: true, message: "请输入联系人", trigger: "blur" },
        ],
        position: [{ required: true, message: "请输入职位", trigger: "blur" }],
        phone: [{ required: true, message: "请输入联系电话", trigger: "blur" }],
        describe: [
          { required: true, message: "请输入需求描述", trigger: "blur" },
        ],
      },
    };
  },
  methods: {
    showForm() {
      this.visible = !this.visible;
    },

    goTop(ev) {
      document.documentElement.scrollTop = 0;
      document.body.scrollTop = 0;
    },
  },
};
</script>
<style>
.popover {
  background: url("@/assets/common/consultingMessageBg@2x.png") !important;
  background-size: 100% 100% !important;
}
</style>
<style lang='scss' scoped>
::v-deep .el-form {
  .el-form-item__label {
    padding: 0 !important;
    height: 35px;
  }
  .el-form-item {
    margin-bottom: 10px;
  }
}

.van-popup--bottom {
  border-radius: 8px 8px 0 0;
}
.phoneDiv {
  display: flex;
  color: #0b66f5;

  .codeDiv {
    width: 100px;
    font-size: 12px;
    text-align: center;
  }
  .imgSize {
    width: 100px;
    margin-right: 20px;
  }
}
.popover {
  background: url("@/assets/common/consultingMessageBg@2x.png");
  .title {
    position: relative;
    margin: 11px 12px 0 15px;
    font-size: 16px;
    font-weight: bold;
    color: #262626;
    line-height: 24px;
    .close {
      position: absolute;
      top: 0;
      right: 0;
      font-size: 18px;
      font-weight: bold;
    }
  }

  .ruleForm {
    margin: 16px 12px 0 15px;
  }

  .bottom {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    .phone {
      color: #2c5afe;
      align-items: center;
      margin-right: 32px;
      .text {
        font-size: 12px;
      }
      .number {
        margin-top: 10px;
        font-size: 14px;
        font-weight: bold;
      }
    }
    .btnSize {
      width: 96px;
      height: 40px;
    }
  }
}
.main {
  position: fixed;
  margin: auto;
  z-index: 100;
  right: 16px;
  //top: 0;
  bottom: 72px;
  width: 36px;
  height: 200px;
  .service {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-wrap: wrap;
    width: 36px;
    height: 105px;
    background: #0b66f5;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.2);
    border-radius: 24px;
    background: url("@/assets/consultingServices/bg@2x.png");
    background-size: 100% 100%;
    cursor: pointer;
    // background: url("@/assets/consultingServices/artificial@2x.png");
    .imgSize {
      margin-top: 10px;
      width: 19px;
      height: 19px;
    }
    .text {
      margin-top: 4px;
      width: 12px;
      font-size: 12px;
      color: #ffffff;

      line-height: 16px;
    }
  }
  .artificial {
    // margin-top: 4px;
    cursor: pointer;
  }
  .goTop {
    // margin-top: 4px;
    cursor: pointer;
  }
}
.popup {
  .title {
    font-size: 14px;
    padding: 16px;
  }
  .popup-content {
    padding: 0 16px;
  }
}
</style>

