export default function setRem() {
    let baseSize = 192 
    // 移动端
    if (document.documentElement.clientWidth <= 992) {
        baseSize = 100 
        // 375为设计稿页面宽度
        const scale = document.documentElement.clientWidth / 375
        // 设置页面根节点字体大小
        document.documentElement.style.fontSize =
            baseSize * Math.min(scale, 2) + 'px'
    } else {
        // pc端
        baseSize = 192
        const scale = document.documentElement.clientWidth / 1920
        // 设置页面根节点字体大小
        document.documentElement.style.fontSize =
            baseSize * Math.min(scale, 2) + 'px'
    }
}