const common = {
  getUrlParam (name = "", href = "", isStorage = true) {
    href = href || location.href;
    let paramsStr = "";
    if (href) {
      [, paramsStr] = href.split("?");
    }

    if (paramsStr) {
      if (paramsStr.indexOf("#/") != -1) {
        paramsStr = paramsStr.split("#/")[0];
      }
      const paramsObj = {};
      let isString = false;
      for (let i = 0; i < paramsStr.split("&").length; i++) {
        const [tempKey, tempVal] = paramsStr.split("&")[i].split("=");
        if (name && typeof name === "string") {
          isString = true;
          if (name.toLowerCase() === tempKey.toLowerCase()) {
            let val;
            if (isStorage) {
              val =
                tempVal ||
                localStorage.getItem(name) ||
                sessionStorage.getItem(name);
            } else {
              val = tempVal;
            }
            return val;
          }
        } else if (Array.isArray(name)) {
          for (let j = 0; j < name.length; j++) {
            if (name[j].toLowerCase() === tempKey.toLowerCase()) {
              if (isStorage) {
                paramsObj[name[j]] =
                  tempVal ||
                  localStorage.getItem(name[j]) ||
                  sessionStorage.getItem(name[j]);
              } else {
                paramsObj[name[j]] = tempVal;
              }
              break;
            }
          }
        } else if (name === "") {
          paramsObj[tempKey] = tempVal;
        }
      }
      if (isString) {
        return null;
      }
      return paramsObj;
    } else {
      let val;
      if (isStorage) {
        val =
          localStorage.getItem(name) || sessionStorage.getItem(name) || null;
      } else {
        val = null;
      }

      return val;
    }

    return null;
  },
}

export default common;