<template>
  <div>
    <Header class="page-header"></Header>
    <router-view class="page-main" :key="$route.fullPath" />
    <template v-if="!isHeadFooterShow">
      <Footer class="page-footer"></Footer>
      <consulting-Services class="page-service"></consulting-Services>
    </template>
  </div>
</template>

<script>
import Header from "@/views/components/Header.vue";
import Footer from "@/views/components/Footer.vue";
import consultingServices from "@/views/components/ConsultingServices.vue";

export default {
  name: "pcHome",
  components: {
    Header,
    Footer,
    consultingServices,
  },
  watch: {
    $route(to, from) {
      this.isHeadFooterShow = window.location.href.includes("/login")|| window.location.href.includes("/appstore") || window.location.href.includes("/configApplication") || window.location.href.includes("/applicationCenter") || window.location.href.includes("/appDetails")
    },
  },
  computed: {
    isHeadShow() {
      return window.location.href.includes("/configApplication")
    },
  },
  data() {
    return {
      isHeadFooterShow: false
    };
  },
};
</script>

<style lang="scss" scoped>
.component {
  height: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}
.page-main {
  /* margin-top: 64px; */
}
</style>
