<template>
  <div
    class="header"
    :style="{
      '--height': showMenu ? '100%' : '',
    }"
  >
    <div class="top">
      <img
        src="@/assets/logo@2x.png"
        class="imgsize"
        v-if="$route.path != '/applicationStation'"
      />
      <div class="logoDown" v-if="$route.path == '/applicationStation'">
        <div class="downContent" @click="changelogo">
          <img src="@/assets/index/company_icon.png" class="imgsize" alt="" />
          <div class="titleBox">
            <p class="title">{{
              userDeptName === "undefined"
                ? userInfo.tenantName
                : userDeptName
            }}</p>
            <van-icon name="arrow-down" />
          </div>
        </div>
      </div>
      <div class="rightbox">
        <div class="userbox" @click="usershow">
          <img v-if="userInfo.realName" src="@/assets/mobile/header/user.png" class="imgsize" alt="" />
          <div v-if="!userInfo.realName">登录</div>
          <div v-if="userInfo.realName" class="userName">
            {{ userInfo.realName }}
          </div>
        </div>
        <img
          src="@/assets/mobile/header/icon.png"
          class="imgsize"
          @click="upMenu"
          style="margin-left: 14px"
        />
      </div>
    </div>
    <el-collapse-transition>
      <div v-show="popshow" class="">
        <div
          class="downbox userdown"
          :class="userDeptList.length > 5 ? 'useheight' : ''"
        >
          <!-- <div class="flex" @click="onCommand('add')">
            <img src="@/assets/mobile/header/add1.png" class="imgsize" alt="" />
            <div class="addtext">创建企业/团队/个人</div>
          </div> -->
          <div
            class="addlogo"
            style="color: #595959"
            v-for="(item, index) in userDeptList"
            :key="index"
            :class="userDeptName == item.name ? 'active' : ''"
            @click="onCommand(item, index)"
          >
            <div class="">
              <img
                src="@/assets/index/company_icon.png"
                style="height: 24px"
                alt=""
              />
              {{ item.clientName }}
            </div>
            <img
              v-if="userDeptName == item.clientName"
              src="@/assets/mobile/header/gou.png"
              style="height: 24px"
              alt=""
            />
          </div>
        </div>
      </div>
    </el-collapse-transition>
    
    <el-collapse-transition>
      <div class="downbox" v-show="popshow">
        <!-- <div class="addtext" @click="changeUrl(1)">进入应用平台</div> -->
        <!-- <div class="addtext" @click="changeUrl(2)" style="color: #595959">
          修改密码
        </div> -->
        <div class="addtext" v-if="loginExitShow" @click="changeUrl(3)" style="color: #ff6043">
          退出登录
        </div>
      </div>
    </el-collapse-transition>
    <van-overlay :show="popshow || logoshow" @click="overlay()" />
    <el-collapse-transition>
      <div class="menu" v-show="showMenu">
        <div class="left">
          <div
            class="left-item"
            @click="clickItem(item)"
            :class="item.key == selectItem.key ? 'active' : ''"
            v-for="(item, index) in menuData"
            :key="item.index"
          >
            {{ item.value }}
          </div>
        </div>
        <div class="right">
          <div v-if="selectItem.key === 1">
            <el-menu default-active="-1" class="el-menu-vertical-demo">
              <el-menu-item
                :index="index + 1 + ''"
                v-for="(item, index) in selectItem.app"
                :key="item.categoryCode"
                @click="goPlatform(item)"
                >{{ item.categoryName }}</el-menu-item
              >
            </el-menu>
          </div>
          <div v-if="selectItem.key === 2">
            <div class="all" @click="goUrl('applicationCenter')">
              全部应用
              <i class="el-icon-arrow-right"></i>
            </div>
            <el-menu default-active="-1" class="el-menu-vertical-demo">
              <el-submenu
                :index="index + 1 + ''"
                v-for="(item, index) in selectItem.child"
                :key="item.categoryCode"
              >
                <template slot="title">
                  <span>{{ item.categoryName }}</span>
                </template>
                <el-menu-item
                  :index="index + 1 + '-' + (index2 + 1)"
                  v-for="(item2, index2) in item.apps"
                  :key="item2.appcode"
                  @click="goAppDetails(item2)"
                  >{{ item2.appname }}</el-menu-item
                >
              </el-submenu>
            </el-menu>
          </div>
        </div>
      </div>
    </el-collapse-transition>
  </div>
</template>

<script>
import {
  getPlatformList,
  listWithApps,
  getUserDept,
  upgrade,
} from "@/api/platformCenter";
import { Popup } from "vant";
import { mapState } from "vuex";
import * as dd from 'dingtalk-jsapi';
export default {
  inject: ["reload"],

  data() {
    return {
      popshow: false,
      logoshow: false,
      addActive: 0,
      title: "",
      activeNames: ["1"],
      showMenu: false,
      tabIndex: 0,
      selectItem: {},
      selectChildItem: -1,
      userDeptList: [],
      menuData: [
        {
          key: 0,
          value: "首页",
          url: "",
          child: [],
        },
        // {
        //   key: 1,
        //   value: "开发平台",
        //   url: "itPage",
        //   child: [],
        // },
        {
          key: 2,
          value: "应用市场",
          url: "applicationCenter",
          child: [],
        },
        {
          key: 3,
          value: "方案&案例",
          url: "schemes",
          child: [],
        },
        {
          key: 4,
          value: "产品动态",
          url: "updateDate",
          child: [],
        },
        {
          key: 5,
          value: "帮助支持",
          url: "helpCenter",
          child: [],
        },
        {
          key: 5,
          value: "关于我们",
          url: "about",
          child: [],
        },
      ],
      loginExitShow: true
    };
  },
  computed: {
    ...mapState(["userInfo", "userDeptName"]),
  },
  created() {
    if (sessionStorage.getItem('loginToken') || localStorage.getItem('loginToken')) {
      this.getTenantList();
    }
    if (dd.env.platform !== 'notInDingTalk' && navigator.userAgent.indexOf('DingTalk') > -1) {
      this.loginExitShow = false
    }
    // getPlatformList({})
    //   .then(({ resdata }) => {
    //     this.menuData[1].child = resdata;
    //     // this.developmentPlatform = resdata;
    //   })
    //   .catch((err) => {});
    listWithApps({ id: "0" })
      .then(({ res }) => {
        this.menuData[1].child = res.data;
      })
      .catch((err) => {});
  },
  watch: {
    $route(to, from) {
      if (this.userInfo.realName) {
        this.getTenantList();
      }
    },
  },
  methods: {
    usershow() {
      if (this.userInfo.realName) {
        if (this.loginExitShow) {
          this.popshow = !this.popshow;
        }
      } else {
        this.goUrl("login");
      }

      this.showMenu = false;
      this.logoshow = false;
    },
    changelogo() {
      this.logoshow = !this.logoshow;
      this.popshow = false;
      this.showMenu = false;
    },
    upMenu() {
      this.selectItem = {};
      this.showMenu = !this.showMenu;
      this.popshow = false;
      this.logoshow = false;
    },
    clickItem(item) {
      console.log("🚀 ~ clickItem ~ item:", item);
      this.selectItem = item;
      if (item.child.length > 0) {
        return;
      }
      this.goUrl(item.url);
    },
    goUrl(url) {
      this.$router.push({
        path: "/" + url,
      });
      this.showMenu = false;
      this.popshow = false;
    },
    goPlatform(item) {
      let url;
      let codeUrl = {
        IT: "itPage",
        OT: "otPage",
        BD: "bigData",
        DT: "digitalTwins",
      };
      url = codeUrl[item.develop_code];
      this.goUrl(url);
    },
    goAppDetails(item) {
      this.showMenu = false;
      this.$router.push({
        path: "/appDetails",
        query: {
          // app_code: item.appcode,
          id: item.id,
        },
      });
    },
    overlay() {
      this.popshow = false;
      this.logoshow = false;
    },
    // onUpdate(item) {
    //   upgrade({ id: item.clientId })
    //     .then(({ res }) => {
    //       // 重新刷新下list
    //       item.isDevelop = !item.isDevelop;
    //       this.$message.success("升级成功");
    //       this.getTenantList();
    //     })
    //     .catch((err) => {});
    // },

    getTenantList() {
      getUserDept({})
        .then(({ res }) => {
          this.userDeptList = res.data;
          this.title = res.data[0].clientName;
          this.$forceUpdate();
        })
        .catch((err) => {});
    },
    onCommand(data, index) {
      if (data == "add") {
        this.$router.push({
          path: "/login",
          query: {
            type: "addCompany",
          },
        });
      } else {
        // this.title = data.name;
        // this.addActive = index;
        this.popshow = false
        this.logoshow = false;
        sessionStorage.setItem("ucenter_client_id", data.clientId);
        localStorage.setItem("ucenter_client_id", data.clientId);
        sessionStorage.setItem("ucenter_client_name", data.clientName)
        this.$store.commit("setUserDeptName", data.clientName);
        // window.location.reload();刷新会闪白
        this.reload(); //provide / inject 组合刷新页面不闪白
      }
    },
    //清空cookie
    clearCookie() {
      var keys = document.cookie.match(/[^ =;]+(?=\=)/g);
      if (keys) {
        for (var i = keys.length; i--; ) {
          document.cookie =
            keys[i] + "=0;path=/;expires=" + new Date(0).toUTCString(); //清除当前域名下的,例如：m.kevis.com
          document.cookie =
            keys[i] +
            "=0;path=/;domain=" +
            document.domain +
            ";expires=" +
            new Date(0).toUTCString(); //清除当前域名下的，例如 .m.kevis.com
          document.cookie =
            keys[i] +
            "=0;path=/;domain=kevis.com;expires=" +
            new Date(0).toUTCString(); //清除一级域名下的或指定的，例如 .kevis.com
        }
      }
      console.log("已清除");
    },
    changeUrl(index) {
      if (index == 1) {
        this.popshow = false;
        // if (this.userInfo.realName) {
        this.$router.push("/applicationStation");
        // } else {
        //   this.$message.error("请先登录");
        //   this.goUrl("login");
        // }
      }
      if (index == 3) {
        this.$store.commit("setUserInfo", {
          username: "",
          realName: "",
          isDevelop: 0,
          mobile: "",
          lastLoginTime: "",
          userId: "",
          userName: "",
          loginCount: 0,
        });
        this.$store.commit("setUserDeptName", "");
        this.$store.commit("setClientId", "");
        sessionStorage.removeItem("userInfo");
        // sessionStorage.removeItem("ucenter_Authorization");
        sessionStorage.removeItem("ucenter_client_id");
        sessionStorage.clear();
        this.clearCookie();
        this.$message.success("退出成功");
        this.goUrl("login");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
// ::v-deep .el-submenu__title:hover {
//   background: RGBA(250, 250, 250, 1);
// }
::v-deep .el-menu {
  background: RGBA(250, 250, 250, 1);
}

::v-deep .el-submenu {
  background: RGBA(250, 250, 250, 1);
}

::v-deep [class*=" el-icon-"] {
  font-weight: bold;
  font-size: 14px;
}

::v-deep .el-icon-arrow-right:before {
  font-weight: bold;
  font-size: 14px;
}

::v-deep .el-menu-item:focus,
::v-deep .el-menu-item:hover {
  background-color: #e8f1ff;
  color: #0b66f5;
}

.header {
  position: fixed;
  z-index: 100;
  background: #fff;
  width: 100%;
  height: var(--height);

  .top {
    padding: 11px 12px;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #f5f5f5;

    .imgsize {
      height: 24px;
    }

    .rightbox {
      display: flex;
      align-items: center;

      .userbox {
        display: flex;
        align-items: center;
        color: #595959;
        font-size: 15px;
        line-height: 16px;
        font-weight: 400;
      }
    }
  }

  .menu {
    background: #fff;
    display: flex;
    height: 100%;

    .left {
      background: #fff;

      .left-item {
        box-sizing: border-box;
        width: 120px;
        padding: 0 12px;
        height: 43px;
        line-height: 43px;
        font-size: 14px;
      }

      .active {
        background: #fafafa;
        color: #0b66f5;
      }
    }

    .right {
      flex: 1;
      background: #fafafa;
      overflow: auto;
      height: calc(100vh - 50px);

      .all:active {
        background: #e8f1ff;
        color: #0b66f5;
      }

      .all {
        position: relative;
        // background: #fff;
        height: 33px;
        line-height: 33px;
        padding: 0 20px;
        font-size: 14px;

        i {
          position: absolute;
          top: 50%;
          right: 20px;
          margin-top: -7px;
          color: #909399;
        }
      }

      .right-item {
        box-sizing: border-box;
        padding: 0 16px;
        height: 33px;
        line-height: 33px;
        font-size: 12px;
      }

      .active {
        background: #e8f1ff;
        color: #0b66f5;
      }

      ::v-deep .el-menu {
        .el-submenu__title {
          height: 40px;
          line-height: 40px;
        }

        .el-menu-item {
          height: 40px;
          line-height: 40px;
        }

        // .el-menu--inline {
        .is-active {
          background: #e8f1ff;
          color: #0b66f5;
        }

        // }
      }
    }
  }
}
.downContent {
  display: flex;
  align-items: center;
  font-size: 16px;
  .titleBox {
    display: flex;
    align-items: center;
    color: #262626;
    ::v-deep .van-icon-arrow-down:before {
      color: #8c8c8c;
    }
    .title {
      margin-left: 5px;
      width: 70px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  .van-popup--top {
    top: 40px;
  }
}
.downbox {
  background: #fff;
  padding: 0 16px;
  position: relative;
  z-index: 999;
  .addtext {
    padding: 10px 0;
    display: flex;
    justify-content: center;
    font-size: 14px;
    color: #0b66f5;
    border-bottom: 1px solid #dedede;
    &:last-child {
      border-bottom: none;
    }
  }
}
.useheight {
  height: 300px;
  overflow-y: auto;
}
.userdown {
  padding: 0px;

  .flex {
    display: flex;
    justify-content: center;
    align-items: center;
    .imgsize {
      height: 16px;
      margin-right: 8px;
    }
  }
  .addlogo {
    min-height: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 16px;
    color: #262626;
    // margin-left: 8px;
    padding: 0 14px;
  }
  .active {
    background: rgba(11, 102, 245, 0.05);
  }
}
::v-deep .van-overlay {
  top: 80px;
}
</style>
