import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "amfe-flexible";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
Vue.use(ElementUI);

// 导入vant包 和其相关样式
import Vant from "vant";
import "vant/lib/index.css";
Vue.use(Vant);

// 创建全局EventBus
var EventBus = new Vue();
Object.defineProperties(Vue.prototype, {
  $bus: {
    get: function () {
      return EventBus;
    },
  },
});

store.commit("updateState", { clientId: "1010203040506070809", appCode: "iiot" });
// import VConsole from 'vconsole';
// const vconsole = new VConsole()
// Vue.use(vconsole)

Vue.config.productionTip = false;
//点击跳转后固定在页面头部
router.beforeEach((to, from, next) => {
  document.body.scrollTop = 0;
  document.documentElement.scrollTop = 0;
  window.pageYOffset = 0;
  next();
});
let vue = new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");

export default vue;
