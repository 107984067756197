<template>
  <div class="main">
    <el-popover
      class="pop"
      popper-class="popover"
      placement="left"
      width="392"
      trigger="manual"
      v-model="visible"
    >
      <div class="title">
        咨询留言
        <i class="close el-icon-close" @click="showForm"></i>
      </div>
      <consulting-message @showForm="showForm"></consulting-message>

      <div class="service" slot="reference" @click="showForm">
        <img
          class="imgSize"
          src="@/assets/consultingServices/message@2x.png"
          alt=""
        />
        <div class="text">服 务 咨 询</div>
      </div>
    </el-popover>

    <div class="artificial">
      <el-popover popper-class="artificial-popover-wrapper" placement="right" width="220" trigger="click">
        <div class="phoneDiv">
          <div class="codeDiv">
            <span class="text">微信公众号</span>
            <img class="imgSize" src="@/assets/index/code2.png" alt="" />
          </div>
          <div class="codeDiv" style="margin-left: -10px;">
            <span class="text">钉钉联系方式</span>
            <img class="imgSize" src="@/assets/index/code1.png" alt="" />
          </div>

          <!-- <img class="imgSize" src="@/assets/consultingServices/phone@2x.png" alt="">
        400-6136-656 -->
        </div>
        <img
          slot="reference"
          src="@/assets/consultingServices/artificial@2x.png"
          alt=""
        />
      </el-popover>
    </div>
    <div class="goTop" @click="goTop">
      <img src="@/assets/consultingServices/goTop@2x.png" alt="" />
    </div>
  </div>
</template>

<script>
import ConsultingMessage from "@/views/components/ConsultingMessage.vue";
export default {
  components: {
    ConsultingMessage,
  },
  data() {
    return {
      visible: false,
      ruleForm: {
        company: "",
        contacts: "",
        position: "",
        phone: "",
        describe: "",
      },
      rules: {
        company: [
          { required: true, message: "请输入公司名称", trigger: "blur" },
        ],
        contacts: [
          { required: true, message: "请输入联系人", trigger: "blur" },
        ],
        position: [{ required: true, message: "请输入职位", trigger: "blur" }],
        phone: [{ required: true, message: "请输入联系电话", trigger: "blur" }],
        describe: [
          { required: true, message: "请输入需求描述", trigger: "blur" },
        ],
      },
    };
  },
  methods: {
    showForm() {
      this.visible = !this.visible;
    },

    goTop(ev) {
      document.documentElement.scrollTop = 0;
    },
  },
};
</script>
<style>
.pop {
  top: 250px !important;
}
.popover {
  background: url("@/assets/common/consultingMessageBg@2x.png") !important;
  background-size: 100% 100% !important;
}
</style>
<style lang='scss' scoped>
::v-deep .el-form-item__label {
  padding: 0 !important;
}

.phoneDiv {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #0b66f5;

  .codeDiv {
    width: 122px;
    font-size: 12px;
    text-align: center;
    padding-top: 10px;
  }
  .imgSize {
    width: 122px;
    margin-right: 20px;
    margin-top: -3px;
  }
}
.popover {
  background: url("@/assets/common/consultingMessageBg@2x.png");
  .title {
    position: relative;
    margin: 11px 12px 0 15px;
    font-size: 16px;
    font-weight: bold;
    color: #262626;
    line-height: 24px;
    .close {
      position: absolute;
      top: 0;
      right: 0;
      font-size: 18px;
      font-weight: bold;
    }
  }

  .ruleForm {
    margin: 16px 12px 0 15px;
  }

  .bottom {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    .phone {
      color: #2c5afe;
      align-items: center;
      margin-right: 32px;
      .text {
        font-size: 12px;
      }
      .number {
        margin-top: 10px;
        font-size: 14px;
        font-weight: bold;
      }
    }
    .btnSize {
      width: 96px;
      height: 40px;
    }
  }
}
.main {
  position: fixed;
  margin: auto;
  z-index: 100;
  right: 30px;
  bottom: 20%;

  width: 48px;
  height: 200px;

  .service {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-wrap: wrap;
    width: 48px;
    height: 137px;
    background: #0b66f5;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.2);
    border-radius: 24px;
    background: url("@/assets/consultingServices/bg@2x.png");
    background-size: 100% 100%;
    cursor: pointer;
    // background: url("@/assets/consultingServices/artificial@2x.png");
    .imgSize {
      margin-top: 16px;
      width: 28px;
      height: 28px;
    }
    .text {
      margin-top: 5px;
      width: 14px;
      font-size: 14px;
      font-weight: 400;
      color: #ffffff;
      line-height: 18px;
    }
  }
  .artificial {
    margin-top: 16px;
    cursor: pointer;
  }
  .goTop {
    margin-top: 16px;
    cursor: pointer;
  }
}
</style>
<style lang="scss">
  .artificial-popover-wrapper {
    width: 239px !important;
    padding: 0 !important;
  }
</style>

