import CryptoJS from 'crypto-js';

const common = {
  KEY: 'abcdefghijklmnopqrstuvwxyz012345', // 32位
  IV: '9876543210abcdef', // 16位
  /** ************************************************************
   *Crypto字符串加密
   *   str：需要加密的字符串
   *************************************************************** */
  Encrypt(str, userkey) {
    let key = CryptoJS.enc.Utf8.parse(common.KEY);
    const iv = CryptoJS.enc.Utf8.parse(common.IV);
    if (userkey) {
      key = CryptoJS.enc.Utf8.parse(userkey);
    }

    let encrypted = '';

    const srcs = CryptoJS.enc.Utf8.parse(str);
    encrypted = CryptoJS.AES.encrypt(srcs, key, {
      iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    });

    return encrypted.ciphertext.toString();
  },

  /** ************************************************************
   *Crypto字符串解密
   *   str：需要解密的字符串
   *************************************************************** */
  Decrypt(str, userkey) {
    let key = CryptoJS.enc.Utf8.parse(this.KEY);
    const iv = CryptoJS.enc.Utf8.parse(this.IV);
    if (userkey) {
      key = CryptoJS.enc.Utf8.parse(userkey);
    }
    const encryptedHexStr = CryptoJS.enc.Hex.parse(str);
    // var encryptedHexStr = CryptoJS.enc.Base64.parse(str);
    // var encryptedHexStr = CryptoJS.enc.Utf8.parse(str);
    const srcs = CryptoJS.enc.Base64.stringify(encryptedHexStr);
    const decrypt = CryptoJS.AES.decrypt(srcs, key, {
      iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    });
    let decryptedStr = 'right';
    try {
      decryptedStr = decrypt.toString(CryptoJS.enc.Utf8);
    } catch (err) {
      decryptedStr = 'error';
    }
    return decryptedStr.toString();
  },
};

export default common;

